import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/SvgIcon/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Unstable_Grid2/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/src/app/[lang]/(auth)/auth-layout.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/src/assets/images/auth-splash.webp");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSwitcher"] */ "/home/runner/work/client/client/apps/web/src/components/language-switcher.tsx");
