'use client';
import { ConfirmNavigationContext } from '@/providers/confirm-navigation-provider';
import { decomposeUrl } from '@/utils/decompose-url';
import { useRouter as useNextRouter } from 'next-nprogress-bar';
import { useContext, useMemo } from 'react';
import useStableCallback from './use-stable-callback';
import { useLingui } from '@lingui/react';

/**
 * A custom router hook that extends Next.js router functionality.
 * This hook patches the Next.js router to include:
 * 1. A loading progress bar for navigation
 * 2. Navigation confirmation prompts
 *
 * It wraps the router methods (push, replace, back, forward, refresh) with
 * confirmation checks before allowing navigation.
 *
 * @returns A patched router object with the same interface as Next.js router
 */
export function useRouter(): ReturnType<typeof useNextRouter> {
  const [shouldConfirm] = useContext(ConfirmNavigationContext);
  const lingui = useLingui();

  const handleConfirm = useStableCallback(() => {
    if (shouldConfirm) {
      return window.confirm(
        lingui.i18n.t({
          id: 'confirm-navigation.message',
          message: 'Are you sure you want to leave this page?'
        })
      );
    }

    return true;
  });

  const router = useNextRouter();

  return useMemo(
    () => ({
      ...router,
      push(inputHref, options, NProgressOptions) {
        if (handleConfirm()) {
          const { href, isLocal, path } = decomposeUrl(inputHref);
          return router.push(isLocal ? path : href, options, NProgressOptions);
        }
      },
      replace(inputHref, options, NProgressOptions) {
        if (handleConfirm()) {
          const { href, isLocal, path } = decomposeUrl(inputHref);
          return router.replace(
            isLocal ? path : href,
            options,
            NProgressOptions
          );
        }
      },
      back(NProgressOptions) {
        if (handleConfirm()) {
          return router.back(NProgressOptions);
        }
      },
      forward() {
        if (handleConfirm()) {
          return router.forward();
        }
      }
    }),
    [handleConfirm, router]
  );
}
